import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BookingService } from '../booking.service';
import { AvailableDays } from '../model/availableDays';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css'],
})
export class BookingComponent implements OnInit {
  availableDays: AvailableDays[];
  countAvailableDays = 0;
  loadingDays = false;

  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private bookingService: BookingService,
    private router: Router
  ) {}

  chooseDayFormGroup = this.formBuilder.group({
    day: ['', [Validators.required]],
  });

  massageFormGroup = this.formBuilder.group({
    massageType: ['', [Validators.required]],
  });

  personalInformationFormGroup = this.formBuilder.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    tel: ['', [Validators.required]],
  });

  ngOnInit(): void {
    this.loadingDays = true;
    this.bookingService.getAllAvailableDays().subscribe(
      (data) => {
        this.loadingDays = false;
        this.availableDays = JSON.parse(JSON.stringify(data));
        this.countAvailableDays = this.availableDays.length;
      },
      (err) => {
        this.loadingDays = false;
        console.log(err);
        this.countAvailableDays = 0;
      }
    );
  }

  confirmReservation() {
    this.loading = true;

    let chooseDayFormGroup = this.chooseDayFormGroup.value;
    let personalInformationFormGroup = this.personalInformationFormGroup.value;

    let day = chooseDayFormGroup.day;
    let firstName = personalInformationFormGroup.firstName;
    let lastName = personalInformationFormGroup.lastName;
    let email = personalInformationFormGroup.email;
    let tel = personalInformationFormGroup.tel;
    let massageType = '';
    switch (this.massageFormGroup.value.massageType) {
      case 0:
        massageType = 'Integratieve massage';
        break;
      case 1:
        massageType = 'Massage bij uw thuis';
        break;
      default:
        break;
    }

    console.log(day + firstName + lastName + email + tel + massageType);

    let data = {
      voornaam: firstName,
      achternaam: lastName,
      email: email,
      tel: tel,
      id: day,
      massageType: massageType,
    };

    this.bookingService.makeReservation(data, day).subscribe(
      (data) => {
        this.loading = false;
        console.log(data);
        console.log('booking success.');
        this.router.navigateByUrl('/confirmation', data);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
