import { Injectable } from '@angular/core';
import {
  CanActivate,
} from '@angular/router';
import { JWTTokenService } from './jwttoken.service';
import { LocalStorageService } from './local-storage.service';
import { LoginService } from './login.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private authStorageService: LocalStorageService,
    private jwtService: JWTTokenService,
    private router: Router
  ) {}
  canActivate() {
    this.jwtService.setToken(localStorage.getItem("token"));
    if (this.jwtService.getUser()) {
      if (this.jwtService.isTokenExpired()) {
        this.router.navigate(['/login'])
      } else {
        return true
      }
    }
  }
}
