import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BookingService } from '../booking.service';
import { Dag } from '../model/dag';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  displayedColumns: string[] = ['delete', 'id', 'day', 'booked'];
  dataSource = new MatTableDataSource<Dag>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading = false;
  todayDate: Date = new Date();

  addBookingForm = this.formBuilder.group({
    datum: ['', Validators.required],
  });

  constructor(
    private bookingService: BookingService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getAllDays();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllDays() {
    this.bookingService.getAllDays().subscribe(
      (data) => {
        let body = JSON.parse(JSON.stringify(data));
        this.dataSource.data = body;
      },
      (err) => {}
    );
  }

  addBooking(addBookingForm): void {
    this.loading = true;
    console.log(this.addBookingForm.value.datum);
    const data = {
      datum: this.addBookingForm.value.datum,
    };
    console.log(data);
    this.bookingService.addDay(data).subscribe(
      (data) => {
        this.loading = false;
        this.getAllDays();
        console.log(data);
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  removeBooking(id): void {
    this.bookingService.removeDay(id).subscribe(
      (data) => {
        this.getAllDays();
        console.log(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
}
