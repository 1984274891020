import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private apiUrl = 'https://us-central1-deblauwevlinder-fc6ce.cloudfunctions.net/app/';
  private contentHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.contentHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
  }

  getAllDays(){
    return this.http.get(this.apiUrl + 'boeken/all', {
      headers: this.contentHeaders.set(
        'Authorization',
        'Bearer ' + this.getToken()
      )
    });
  }

  getAllAvailableDays() {
    return this.http.get(this.apiUrl + 'boeken', {
      headers: this.contentHeaders.set(
        'Authorization',
        'Bearer ' + this.getToken()
      )
    });
  }

  makeReservation(data, day) {
    return this.http.put(this.apiUrl + 'boeken/' + day, data, {
      headers: this.contentHeaders.set(
        'Authorization',
        'Bearer ' + this.getToken()
      )
    });
  }

  addDay(data){
    return this.http.post(this.apiUrl + 'boeken/add', data, {
      headers: this.contentHeaders.set(
        'Authorization',
        'Bearer ' + this.getToken()
      )
    });
  }

  removeDay(id){
    return this.http.delete(this.apiUrl + 'boeken/remove/' + id, {
      headers: this.contentHeaders.set(
        'Authorization',
        'Bearer ' + this.getToken()
      )
    });
  }

  getToken() {
    return localStorage.getItem('token');
  }
}
