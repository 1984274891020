<div class="booking">
  <h1>Online reservatie massage</h1>
  <mat-vertical-stepper #stepper [linear]="true">
    <mat-step
      label="Datum reservering"
      state="date_range"
      [stepControl]="chooseDayFormGroup"
      errorMessage="Selecteer een dag"
    >
      <form [formGroup]="chooseDayFormGroup">
        <mat-spinner *ngIf="loadingDays"></mat-spinner>
        <div *ngIf="countAvailableDays > 0 && !loadingDays">
          <mat-radio-group
            aria-label="Selecteer een dag"
            formControlName="day"
            class="radio"
          >
            <mat-radio-button
              color="primary"
              *ngFor="let day of availableDays"
              [value]="day.id"
              required
              >{{ day.datum | date : "d MMMM y, HH:mm:ss" }}</mat-radio-button
            >
          </mat-radio-group>
          <div>
            <button mat-button matStepperNext>Volgende</button>
          </div>
        </div>
        <div *ngIf="countAvailableDays == 0 && !loadingDays">
          Er zijn geen dagen meer beschikbaar.
        </div>
      </form>
    </mat-step>
    <mat-step
      label="Soort massage"
      [stepControl]="massageFormGroup"
      errorMessage="Selecteer een massage"
    >
      <form [formGroup]="massageFormGroup">
        <mat-radio-group
          aria-label="Selecteer een massage"
          formControlName="massageType"
          class="radio"
        >
          <mat-radio-button color="primary" [value]="0" required
            >Integratieve massage (75 euro)</mat-radio-button
          >
          <p>
            Een integratieve massage duurt 75 min + korte voor en nabespreking.
          </p>
          <mat-radio-button color="primary" [value]="1" required
            >Massage bij uw thuis (75 euro)</mat-radio-button
          >
          <p>
            Zie je er tegenop om tot bij ons te komen of wil je een gezellig
            avondje thuis niet onderbreken? Redenen genoeg voor een massage aan
            huis! Wij komen graag tot bij jou in zowel provincie Limburg als
            Antwerpen. Daarnaast zorgen wij voor het nodige materiaal zoals een
            massagetafel, handdoeken, olie en een rustgevend muziekje. De
            verplaatsingskosten zijn aan 0,42 EUR/km vanaf de praktijk te
            Beveren.
          </p>
          <p>
            De massage zelf duurt 75 min. Excl. verplaatsingskosten (0.42EUR/km)
          </p>
        </mat-radio-group>
        <div>
          <button mat-button matStepperNext>Volgende</button>
        </div>
      </form>
    </mat-step>
    <mat-step
      label="Persoonlijke informatie"
      state="person"
      [stepControl]="personalInformationFormGroup"
      errorMessage="Vul alle informatie in"
    >
      <form [formGroup]="personalInformationFormGroup" style="margin-top: 15px">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Voornaam</mat-label>
          <input type="text" matInput formControlName="firstName" required />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Achternaam</mat-label>
          <input type="text" matInput formControlName="lastName" required />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            formControlName="email"
            placeholder="Ex. pat@example.com"
            required
          />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Telefoon nummer</mat-label>
          <input type="text" matInput formControlName="tel" required />
        </mat-form-field>
        <div>
          <button mat-button matStepperNext>Volgende</button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Bevestigen">
      <br />
      <p>
        Op alle massages zijn de regels van het huis & de algemene voorwaarden
        van toepassing (verkrijgbaar per mail en aanwezig in de massageruimte).
      </p>
      <mat-spinner *ngIf="loading; else elseBlock"></mat-spinner>
      <ng-template #elseBlock>
        <button mat-button color="primary" (click)="confirmReservation()">
          Bevestig reservatie
        </button>
      </ng-template>
    </mat-step>

    <ng-template matStepperIcon="person">
      <mat-icon>person</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="date_range">
      <mat-icon>date_range</mat-icon>
    </ng-template>
  </mat-vertical-stepper>
</div>
