<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    autoFocus="false"
    mode="over"
    [(opened)]="isMenuOpen"
    class="sidenav"
    (click)="onSidenavClick()"
  >
    <a mat-button routerLink="/">Home</a>
    <a mat-button routerLink="" fragment="over-mij">Over mij</a>
    <a mat-button routerLink="" fragment="massages">Aanbod Massages</a>
    <a mat-button routerLink="" fragment="contact">Contact</a>
    <a mat-button routerLink="/booking">Reserveer een massage</a>
  </mat-sidenav>
  <mat-sidenav-content>
    <nav
      class="nav"
      *ngIf="isHomeRoute()"
      [ngClass]="{ blue: this.router.url === '/booking' || this.router.url === '/confirmation'}"
    >
      <button mat-button class="menu-button" (click)="sidenav.toggle()">
        <mat-icon>{{ isMenuOpen ? "menu_open" : "menu" }}</mat-icon>
      </button>
      <span class="logo"><img src="./assets/logo.jpg" alt="logo" /></span>
      <ul class="nav-items">
        <li><a mat-button routerLink="/">Home</a></li>
        <li><a mat-button routerLink="" fragment="over-mij">Over mij</a></li>
        <li>
          <a mat-button routerLink="" fragment="massages">Aanbod Massages</a>
        </li>
        <li><a mat-button routerLink="" fragment="contact">Contact</a></li>
        <li class="right">
          <a mat-button routerLink="/booking">Reserveer een massage</a>
        </li>
      </ul>
    </nav>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
