<div id="home" class="home">
  <h1>Blauwe Vlinder Massage</h1>
  <h2>AANRAKEN, AANGERAAKT & GERAAKT WORDEN</h2>
  <svg class="curve" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      fill="#f3f4f5"
      fill-opacity="1"
      d="M0,192L80,208C160,224,320,256,480,240C640,224,800,160,960,144C1120,128,1280,160,1360,176L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
    ></path>
  </svg>
</div>

<!--Over mij-->

<div id="over-mij" class="over-mij">
  <img class="logo" src="./assets/SVH_8983.JPG" alt="Kris de Potter" />
  <div class="text">
    <h2>Wie ben ik?</h2>
    <p>
      <span>
        Mijn naam is Kris de Potter en bijna 30 jaar geleden ontdekte ik het
        belang van biologische voeding en gezondheid alsook van
        lichaamsverzorging. Sinds een jaar of 10 ben ik aan de slag met
        lichaamswerk en dans o.a. salsa, biodanza, ecstatic dance, …
      </span>
    </p>
    <p>
      <span>
        Massage therapie heeft me steeds kunnen bekoren-Het geeft dan ook een
        diepe ontspanning. Het begon als recreatief vrienden en kennissen
        masseren. De regelmatige positieve feedback zette me ertoe aan om in
        2016 de stap te zetten in een eerste cursus Reiki, gevolgd door tantra,
        basis Yin Shin Jyutsu en ander lichaamswerk. In 2018 heb ik veel
        voldoening gehad bij de jaaropleiding Integratieve massage van Geert Van
        Coillie. Dat is nu de massagevorm die ik aanbied.
      </span>
    </p>
    <p>
      <span
        >Ik voel aan wat nodig is door me af te stemmen op jouw lichaam en dan
        …dans ik de massage.</span
      >
    </p>
    <p>
      <span
        >Vanuit mijn opleiding sjamanisme integreer ik de helende krachten
        tijdens de polarisaties.</span
      >
    </p>
    <h2>Waarom bij mij?</h2>
    <p>
      <span>
        Uit de feedback die ik reeds mocht ontvangen kan ik aangeven dat men mij
        ervaart als een masseur met een goede attitude, met volle aandacht voor
        mijn klanten. Ik laat mijn handen spreken. Afwisseling in de masserende
        bewegingen ontstaat net zoals jij er - via je gemasseerde lichaam- naar
        “vraagt”. Ik masseer vanuit hart en ziel. Mijn doel is je diepe rust en
        ontspanning te brengen.</span
      >
    </p>
    <h2>Waarom Blauwe Vlinder?</h2>
    <p>
      <span
        >Als kind was ik gefascineerd door een blauwe vlinder die ik cadeau
        kreeg. Deze mooie vlinder staat voor mij symbool voor het verspreiden
        van onvoorwaardelijke liefde naar anderen via communicatie. Het voelt
        als mijn bestemming om anderen te laten delen in het ontvangen van
        onvoorwaardelijke liefde en dit wil ik graag neerzetten via massage
        therapie.</span
      >
    </p>
    <p>
      <span
        >Ben je onrustig? Voel je spanning in je lijf? Lig je daar ‘s nachts
        wakker van? Heb je nood aan integere aanraking? Heb je het gevoel dat je
        uit balans bent? Dan is een integratieve massage iets voor jou. Maak nu
        een afspraak</span
      >
    </p>
    <p>
      <span
        >Op alle massages zijn de regels van het huis & de algemene voorwaarden
        van toepassing (beschikbaar op aanvraag en in de massageruimte)</span
      >
    </p>
  </div>
</div>

<!--Massages-->

<div id="massages" class="massages">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      fill="#f3f4f5"
      fill-opacity="1"
      d="M0,224L80,234.7C160,245,320,267,480,250.7C640,235,800,181,960,165.3C1120,149,1280,171,1360,181.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
    ></path>
  </svg>
  <h2>Aanbod Massages</h2>
  <div class="massages-list">
    <div class="massage">
      <img src="/assets/SVH_9477.JPG" alt="Integratieve Massage" />
      <div class="text">
        <h2>Integratieve Massage</h2>
        <p>
          <span
            >Bij een Integratieve massage worden 3 verschillende massagevormen
            gecombineerd tot 1 vloeiend geheel. Een dynamische massage (pulsing)
            waarbij je op zachte en ritmische wijze wordt gewiegd, gerold,
            gerekten losgeschud. Dit werkt op een erg diep niveau openend en
            ontspannend.</span
          >
        </p>
        <p>
          <span>
            Daarna volgt een intuïtieve massage waarbij rustige strijkingen
            afgewisseld worden met diepere knedingen en fricties die bijdragen
            bij aan een tedere weldaad voor lichaam en geest.
          </span>
        </p>
        <p>
          <span
            >Tenslotte wordt geëindigd met een polariteitsmassage. Door de
            handen zachtjes op verschillende plaatsen neer te leggen, kan de
            levensenergie in het lichaam vrijer gaan stromen.</span
          >
        </p>
      </div>
    </div>
    <div class="massage">
      <img
        mat-card-image
        src="/assets/SVH_9463.JPG"
        alt="Massage bij u thuis"
      />
      <div class="text">
        <h2>Massage bij u thuis</h2>
        <p>
          <span
            >Je wordt in je eigen omgeving gemasseerd door een professionele
            holistisch masseur. Ik neem handdoeken, olie, massagemuziek en een
            massagetafel mee. Je hoeft alleen te zorgen voor een rustige ruimte,
            waar de massage plaats kan vinden. Ik maak vaak gebruik van een
            combinatie van verschillende massagetechnieken. Ik houd hierbij
            rekening met wat er op dit moment bij jou om aandacht vraagt. De
            massages worden zoveel mogelijk afgestemd op jouw behoefte aan
            massage.</span
          >
        </p>
      </div>
    </div>
    <!-- <mat-card class="massage">
      <mat-card-header>
        <mat-card-title>Integratieve Massage</mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="/assets/SVH_9480.JPG"
        alt="Integratieve Massage"
      />
      <mat-card-content
        ><p>Bij een Integratieve massage worden 3 verschillende massagevormen
        gecombineerd tot 1 vloeiend geheel. Een dynamische massage (pulsing)
        waarbij je op zachte en ritmische wijze wordt gewiegd, gerold, gerekten
        losgeschud. Dit werkt op een erg diep niveau openend en ontspannend.
        Daarna volgt een intuïtieve massage waarbij rustige strijkingen
        afgewisseld worden met diepere knedingen en fricties die bijdragen bij
        aan een tedere weldaad voor lichaam en geest. Tenslotte wordt geëindigd
        met een polariteitsmassage. Door de handen zachtjes op verschillende
        plaatsen neer te leggen, kan de levensenergie in het lichaam vrijer gaan
        stromen.</p> 
      </mat-card-content>
    </mat-card>
    <mat-card class="massage">
      <mat-card-header>
        <mat-card-title>Massage bij u thuis</mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="/assets/SVH_9463.JPG"
        alt="Massage bij u thuis"
      />
      <mat-card-content
        ><p>Je wordt in je eigen omgeving gemasseerd door een professionele
        holistisch masseur. Ik neem handdoeken, olie, massagemuziek en een
        massagetafel mee. Je hoeft alleen te zorgen voor een rustige ruimte,
        waar de massage plaats kan vinden. Ik maak vaak gebruik van een
        combinatie van verschillende massagetechnieken. Ik houd hierbij rekening
        met wat er op dit moment bij jou om aandacht vraagt. De massages worden
        zoveel mogelijk afgestemd op jouw behoefte aan
        massage.</p> </mat-card-content
      >
    </mat-card> -->
    <!-- <mat-card class="massage">
            <mat-card-header>
              <mat-card-title>Massage3</mat-card-title>
            </mat-card-header>
            <img
              mat-card-image
              src="https://material.angular.io/assets/img/examples/shiba2.jpg"
              alt="Photo of a Shiba Inu"
            />
            <mat-card-content
              >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem
              necessitatibus maiores soluta impedit atque quidem omnis? Maxime provident
              explicabo dolores laborum, non dolore sint ut voluptates vel velit in
              quasi.</mat-card-content
            >
          </mat-card> -->
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="curve">
    <path
      fill="#f3f4f5"
      fill-opacity="1"
      d="M0,256L80,213.3C160,171,320,85,480,85.3C640,85,800,171,960,202.7C1120,235,1280,213,1360,202.7L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
    ></path>
  </svg>
</div>

<!--Contact-->

<div id="contact" class="contact">
  <div class="contact-information">
    <ul>
      <li>
        <mat-icon> place</mat-icon>Paul van Ostayenstraat 5, 9120 Beveren-Waas
      </li>
      <li>
        <mat-icon> contact_mail</mat-icon
        ><a href="mailto:kdepotter@gmail.com">kdepotter@gmail.com</a>
      </li>
      <li>
        <mat-icon> local_phone</mat-icon
        ><a href="tel:+32 494 07 77 07">+32 494 07 77 07</a>
      </li>
    </ul>
    <img width="125px" src="./assets/Integratieve massage.png" alt="Integratieve massage">
  </div>
  <div class="map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2499.7660072143344!2d4.242291015645199!3d51.20496317958658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c38babcdc808e7%3A0x7f146b35b4239cda!2sBlauwe%20Vlinder%20Massage!5e0!3m2!1snl!2sbe!4v1629728825683!5m2!1snl!2sbe"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  </div>
</div>

<!--Footer-->

<div class="footer">
  <ul class="footer-links">
    <li><a href="#home">Home</a></li>
    <li><a href="#over-mij">Over mij</a></li>
    <li><a href="#massages">Aanbod Massages</a></li>
    <li><a href="#contact">Contact</a></li>
  </ul>
  <ul class="socials">
    <li>
      <a href="https://www.facebook.com/depotterkris" target="_blank"
        >Facebook</a
      >
    </li>
    <li><a href="mailto:kdepotter@gmail.com">kdepotter@gmail.com</a></li>
    <li><a href="tel:+32 494 07 77 07">+32 494 07 77 07</a></li>
  </ul>
  <ul class="website">
    <li><img src="./assets/logo.jpg" alt="logo" /></li>
    <li style="font-size: 18px">Blauwe Vlinder Massage</li>
    <li>&copy; 2021 Blauwe Vlinder Massage</li>
  </ul>
</div>
