import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'website-kris-angular';

  @HostListener('window:scroll', ['$event'])
  
  onWindowScroll() {
    let element = document.querySelector('.nav') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('nav-inverse');
    } else {
      element.classList.remove('nav-inverse');
    }
  }
}
