<nav class="dashboard-nav">
  <div class="logo">
    <img
      [routerLink]="['/dashboard']"
      src="./assets/logo.jpg"
      alt="LEAP logo"
    />
    <span>Dashboard</span>
  </div>
  <div class="user">
    <img
      class="logout"
      (click)="logout()"
      src="/assets/logout.svg"
      alt="logout"
    />
  </div>
</nav>
<div class="container">
  <div class="days mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let dag">
          <img
            src="assets/delete.svg"
            (click)="removeBooking(dag.id)"
            alt="delete"
            width="20px"
            class="delete"
          />
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let dag">{{ dag.id }}</td>
      </ng-container>

      <!-- Day Column -->
      <ng-container matColumnDef="day">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="dag">Day</th>
        <td mat-cell *matCellDef="let dag">
          {{ dag.dag | date : "dd/MM/y, HH:mm:ss" }}
        </td>
      </ng-container>

      <!-- Booked Column -->
      <ng-container matColumnDef="booked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Booked</th>
        <td mat-cell *matCellDef="let dag">
          {{ dag.reserveerd === 1 ? "Ja" : "Nee" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page"
    >
      ></mat-paginator
    >
  </div>
  <br />
  <h2>Voeg een dag toe</h2>
  <form [formGroup]="addBookingForm" (ngSubmit)="addBooking(addBookingForm)">
    <div class="mb-3">
      <mat-form-field appearance="fill" style="width: 220px">
        <mat-label>Kies een datum</mat-label>
        <input
          matInput
          type="datetime-local"
          [min]="todayDate"
          formControlName="datum"
        />
      </mat-form-field>
    </div>
    <button
      mat-raised-button
      style="margin-right: 5px"
      color="primary"
      type="submit"
      [disabled]="loading || !addBookingForm.valid"
      [class.spinner]="loading"
    >
      Submit
    </button>
    <button mat-button routerLink="..">Go back</button>
  </form>
</div>
