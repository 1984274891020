import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private apiUrl = 'https://us-central1-deblauwevlinder-fc6ce.cloudfunctions.net/app/';
  private contentHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.contentHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
  }


  login(data){
    return this.http.post(this.apiUrl + 'auth/login', data, {
      headers: this.contentHeaders,
    });
  }
}
