import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  public isMenuOpen: boolean = false;

  constructor(private router: Router,) { }

  ngOnInit(): void {
  }

  isHomeRoute(){
    return this.router.url !== '/login'
  }

  public onSidenavClick(): void {
    this.isMenuOpen = false;
  }
}
