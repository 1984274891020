import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppCookieService } from '../app-cookie.service';
import { LocalStorageService } from '../local-storage.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  constructor(
    private loginService: LoginService,
    private router: Router,
    private cookieService: AppCookieService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  @Input() error: string | null;

  submit() {
    let data = {
      email: this.form.value.email,
      password: this.form.value.password,
    };
    console.log(data);
    this.loginService.login(data).subscribe(
      (data) => {
        console.log(data);
        let body = JSON.parse(JSON.stringify(data));
        var token = body.stsTokenManager.accessToken;
        this.localStorageService.set('token', token);
        this.router.navigate(['/dashboard']);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
